import { createContext, useContext, useState } from 'react'

const ChipInputValuesContext = createContext({
  cityInput: '',
  insuranceInput: '',
  setCityInput: (value: any) => value,
  setInsuranceInput: (value: any) => value,
  zipInput: '',
  setZipInput: (value: any) => value,
})

export const useChipInputValues = () => {
  return useContext(ChipInputValuesContext)
}

export const ChipInputValuesProvider = ({ children }) => {
  const [cityInput, setCityInput] = useState('')
  const [insuranceInput, setInsuranceInput] = useState('Uninsured')
  const [zipInput, setZipInput] = useState('')

  return (
    <ChipInputValuesContext.Provider
      value={{
        cityInput,
        insuranceInput,
        setCityInput,
        setInsuranceInput,
        zipInput,
        setZipInput,
      }}
    >
      {children}
    </ChipInputValuesContext.Provider>
  )
}
