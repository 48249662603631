import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './chip-input.scss'

import { useChipInputValues } from '../../../reactLayer/contextStore/homepageStore'
import { useGetSearchParams } from '../../lib/hooks/useGetSearchParams'
import { generateURLParams } from '../../lib/utils'
import { ChipInputIcon, ChipInputXIcon } from '../../style/icons'
import { BOX_SHADOW } from '../../style/reusables'

let autoComplete = null

const loadScript = (url, callback) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'

  // @ts-ignore
  if (script.readyState) {
    // @ts-ignore
    script.onreadystatechange = function () {
      // @ts-ignore
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        // @ts-ignore
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

const handleScriptLoad = (setCity, setZip, setInputValue, autoCompleteRef) => {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ['(regions)'], componentRestrictions: { country: 'us' } }
  )
  autoComplete.addListener('place_changed', () => {
    const place = autoComplete.getPlace()
    setCity(place?.formatted_address)

    const geo = place?.geometry?.location

    const coordinates = {
      lat: geo?.lat(),
      lng: geo?.lng(),
    }

    if (coordinates.lat && coordinates.lng) {
      getCurrentLocationZipCode(coordinates, place?.name, setZip)
    }

    setInputValue(place?.formatted_address)
  })
}

const getCurrentLocationZipCode = async (
  coordinates: { lat: number; lng: number },
  cityName: string,
  setZipCode: (zip: string, cityName: string) => void
) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo&result_type=postal_code`
  try {
    const result = await fetch(url)
    const json = await result.json()
    const zipCode = json.results[0]?.address_components[0].short_name
    setZipCode(zipCode, cityName)
  } catch (e) {
    console.log(e)
  }
}

export const CityChipInput = ({ derivedCityName, derivedZipCode }) => {
  const { cityInput, setCityInput, setZipInput, setInsuranceInput } =
    useChipInputValues()

  const history = useHistory()
  const location = useLocation()
  const query = useGetSearchParams()

  const autoCompleteRef = useRef(null)
  const [inputValue, setInputValue] = useState(derivedCityName)

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo&libraries=places&region=us`,
      () =>
        handleScriptLoad(updateCity, updateZip, setCityInput, autoCompleteRef)
    )
  }, [])

  useEffect(() => {
    setInputValue(derivedCityName)
    setCityInput(derivedCityName)
    setZipInput(derivedZipCode)
  }, [derivedCityName, derivedZipCode])

  useEffect(() => {
    setZipInput(query.zip)
  }, [query.zip])

  const updateCity = (value: string) => {
    setInputValue(value)
    setInsuranceInput('Uninsured')
    history.replace({
      pathname: location.pathname,
      search: generateURLParams({ ...query, city: value }),
    })
  }

  const updateZip = (zip: string, cityName: string) => {
    setZipInput(zip)
    history.replace({
      pathname: location.pathname,
      search: generateURLParams({ ...query, zip: zip, city: cityName }),
    })
  }

  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        style={{ height: '40px', width: '48px' }}
      >
        <ChipInputIcon />
      </InputLeftElement>
      <Input
        style={{
          maxWidth: '220px',
          height: '40px',
          fontSize: '14px',
          fontWeight: 'bold',
          padding: '10px 38px 10px 4px',
          borderRadius: '4px',
          border: '1px solid #077db4',
          color: '#077db4',
        }}
        variant="search"
        textIndent="40px"
        placeholder="City"
        name="find"
        ref={autoCompleteRef}
        type="text"
        {...BOX_SHADOW}
        onChange={(event) => {
          setInputValue(event.target.value)
          setCityInput(event.target.value)
        }}
        value={inputValue}
      />
      {cityInput !== '' && (
        <InputRightElement
          style={{ cursor: 'pointer', height: '40px', width: '48px' }}
        >
          <div onClick={() => setInputValue('')}>
            <ChipInputXIcon />
          </div>
        </InputRightElement>
      )}
    </InputGroup>
  )
}
