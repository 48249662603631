import 'bootstrap/dist/css/bootstrap.css'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useGetCityData } from '../../hooks/getCityData'
import { useIPLocation } from '../../hooks/getIPLocation'
import { CityChipInput } from '../../new/components/chip-input/CityChipInput'
import { InsuranceChipInput } from '../../new/components/chip-input/InsuranceChipInput'
import { useGetSearchParams } from '../../new/lib/hooks/useGetSearchParams'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import { useChipInputValues } from '../../reactLayer/contextStore/homepageStore'
import MedicalFocusArea from '../homepage/MedicalFocusArea'
import TopDoctors from './TopDoctors'

const headers = { 'Content-Type': 'application/json' }

const NewHomePage = () => {
  const authContext = useContext(AuthContext)
  const query = useGetSearchParams()
  const { insuranceInput } = useChipInputValues()

  const { location } = useIPLocation()
  const {
    city: derivedCityName,
    state: derivedState,
    zipCode: derivedZipCode,
  } = useGetCityData(location)
  const [searchResults, setSearchResults] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const city = query.city || derivedCityName
  const zipCode = query.zip || derivedZipCode

  const submitData = {
    headers: headers,
    url: 'providers/search/get/all',
    body: JSON.stringify({
      callApi: false,
      firstName: '',
      lastName: '',
      city: city,
      zip: zipCode,
      speciality: [],
      languages: ['english'],
      insurances:
        insuranceInput === 'Uninsured'
          ? null
          : [query.insurance || insuranceInput],
      focusAreas: 'Primary Care',
      searchValue: '',
      hasVirtualVisit: 0,
      isAcceptingNewPatient: 0,
      available72hours: 0,
      isTopShow: 0,
      isSponsoredShow: 0,
      isGender: '',
      isChildren: false,
      offset: 0,
      limit: 10,
      latitude: authContext.browserLatLng.latitude,
      longitude: authContext.browserLatLng.longitude,
      type: [],
    }),
  }

  const getSearchResult = async () => {
    setIsLoading(true)
    if (authContext.token) {
      headers['access-token'] = authContext.token
    }
    const result = await NetworkLayer.postData(submitData)
    const { data } = result.body.result
    setSearchResults(data)
    setIsLoading(false)
  }

  useEffect(() => {
    if (query.city || derivedCityName) {
      getSearchResult()
    }
  }, [query.city, derivedCityName, query.zip, insuranceInput, query.insurance])

  return (
    <Fragment>
      <div className="home-top-container">
        <Container style={{ maxWidth: '1440px' }}>
          <Row>
            <div className="content-main-container">
              <div className="col-12" style={{ flex: 'unset' }}>
                <div className="content__container--mobile">
                  <h1 className="content__container__text--mobile">
                    Search and schedule local <span>doctors</span>
                  </h1>
                </div>
                <div className="row-container">
                  <div className="content__container">
                    <h1 className="content__container__text">
                      Search and schedule local
                    </h1>
                    <ul className="content__container__list">
                      <li className="content__container__list__item">
                        doctors
                      </li>
                      <li className="content__container__list__item">
                        dentists
                      </li>
                      <li className="content__container__list__item">
                        OBG-YNs
                      </li>
                      <li className="content__container__list__item">
                        pediatricians
                      </li>
                      <li className="content__container__list__item">
                        dermatologists
                      </li>
                      <li className="content__container__list__item">
                        optometrist
                      </li>
                      <li className="content__container__list__item">
                        therapist
                      </li>
                      <li className="content__container__list__item">
                        plastic surgeons
                      </li>
                    </ul>
                  </div>
                  <div className="input-tag-wrapper">
                    <div className="tag">
                      <CityChipInput
                        derivedCityName={derivedCityName + ', ' + derivedState}
                        derivedZipCode={derivedZipCode}
                      />
                    </div>
                    <div className="tag">
                      <InsuranceChipInput />
                    </div>
                  </div>
                </div>
                <span className="main-subheading">
                  We consolidated online reviews, so you don’t have to—book with
                  confidence!
                </span>
              </div>
            </div>
          </Row>
          <Row>
            <MedicalFocusArea />
          </Row>
          <Row>
            <TopDoctors
              city={derivedCityName}
              state={derivedState}
              searchResults={searchResults}
              isLoading={isLoading}
            />
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default NewHomePage
